import { BusService } from '@nsftx/games-bus';
import store from '../store';
import { isEmpty } from 'lodash';
import { eventBus } from '@nsftx/games-sdk-js/src/utility';

let busService;
let initialChannels = [];
let subChannels = {};

const thirdPartyPlatformSubscribe = async () => {
  const { user, applicationName } = store.getters.config;

  if (user && user.id) {
    subChannels = {
      Product: {
        deliveryPlatform: applicationName.toLowerCase(),
      },
    };
    const params = new URLSearchParams(window.location.search);
    let urlParams = {};
    params.forEach((value, key) => {
      urlParams[key] = value;
    });
    if (urlParams.casino) {
      initialChannels.push({
        channelType: 'Balance',
        channelName: `${urlParams.casino}-${user.username}`,
      });
    }
  }
};

const addUserChannel = () => {
  const { user } = store.getters.config;
  initialChannels.push({
    channelType: 'Player',
    channelName: user.id,
  });
};

const init = () => {
  const { config } = store.getters;
  const { environment, platformName, productId, tenantId, locale, applicationName, messageProtocol, user } = config;

  if (platformName !== 'seven') {
    thirdPartyPlatformSubscribe();
  }

  if (user && user.id) {
    addUserChannel();
  }

  const settings = {
    environment: environment,
    platform: platformName,
    productName: 'LuckyX',
    productId: productId,
    tenantId: tenantId,
    locale: locale,
    applicationName: applicationName,
    useCommonEventName: true,
    adapters: [{ name: 'GcmAdapter' }],
    clientMessageProtocol: messageProtocol,
    bounceToFrames: [{ frameId: 'luckyxVisualization' }],
    subChannels: subChannels,
    initialChannels: initialChannels,
  };

  try {
    busService = new BusService(settings);
    busService.start();
  } catch (error) {
    // eslint-disable-next-line
    console.error('Bus service error!', error);
  }
};

const addChannel = (channelType, channelName) => {
  busService.addChannel(channelType, channelName);
};

const removeChannel = (channelType, channelName) => {
  busService.removeChannel(channelType, channelName);
};

const sendMessage = (action, data) => {
  busService?.sendMessage(
    {
      action,
      data,
    },
    true,
  );
};

const sendMessageAsync = (action, data) => {
  busService?.clientAdapter.handler
    .emitAsync({
      action,
      data,
    })
    .then((response) => {
      const { ticket } = response;
      if (action === 'Tickets.Pay') eventBus.$emit('CheckTicket', ticket.requestUuid);
    })
    .catch((error) => {
      if (action === 'Tickets.Pay') {
        store.dispatch('gamesBetslip/setIsPayinInProgress', false);
        store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
      }
      const listOfErrorCodesToIgnore = ['T_TICKET_RESOLVE_BACKEND_ERROR'];
      if (!isEmpty(error) && listOfErrorCodesToIgnore.indexOf(error.clientCode) > -1) {
        const notification = {
          type: 'warning',
          message: store.getters.getTranslation(error.serverResponse?.message),
        };
        if (notification.message) {
          busService.sendMessage('Dialog.Show', notification);
        }
      }
    });
};

export default {
  init,
  addChannel,
  removeChannel,
  sendMessage,
  sendMessageAsync,
};
